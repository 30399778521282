import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';
import {
    elementScrollIntoViewPolyfill,
    elementScrollToPolyfill,
    windowScrollToPolyfill,
} from 'seamless-scroll-polyfill';
import { AuthenticationProvider } from '@twigeducation/oidc-client-react';
import { Provider, connect } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { LoadManifests } from '@twigeducation/async-component';
import styled, { ThemeProvider } from 'styled-components';
import { middleSchoolTheme, GlobalStyle, PageError } from '@twigeducation/ts-fe-components';
import { UnleashConsumer } from '@twigeducation/unleash-client-react';
import * as Sentry from '@sentry/react';
import { makeMultiplexedTransport } from '@sentry/core';
import SentryRRWeb from '@sentry/rrweb';
import ErrorBoundary from './components/ErrorBoundary';
import { setFeatureFlags } from './store/actions/featureFlags';
import CustomUnleash from './components/Unleash';
import App from './App';
import history from './history';
import Routes from './Routes';
import userManager from './Authentication/userManager';
import store from './store/store';
import Subscriptions from './components/Subscriptions/Subscriptions';
import client from './apolloClient';
import './microExport';
import './i18n';
import GlobalFonts from './GlobalFonts';
import 'react-toastify/dist/ReactToastify.css';
import I18n from './components/I18n';
import DevTools from './components/DevTools';
import GlobalReadSpeakerStyles from './components/ReadSpeaker/ReadSpeaker.styles';
import { initGA } from './helpers/ga';

elementScrollIntoViewPolyfill();
elementScrollToPolyfill();
windowScrollToPolyfill();

window.app = new App();

if (typeof window.config.SENTRY_PUBLIC_DSN === 'string' && window.config.SENTRY_PUBLIC_DSN !== '') {
    const dsnFromFeature = ({ getEvent }) => {
        const event = getEvent();
        switch (event?.tags?.mfe) {
            case 'ms-assessment-explorer-mfe':
                return ['https://20ed8e295fec47d7abd110226ec240fd@o11481.ingest.sentry.io/4505209945128960'];
            case 'ms-asset-preview-mfe':
                return ['https://1d4722145a3b40e6b8a39fb9aa6095eb@o11481.ingest.sentry.io/4505209948864512'];
            case 'ms-assign-share-mfe':
                return ['https://e5f3132a87b543dd952058389df03f5d@o11481.ingest.sentry.io/4505209950306304'];
            case 'ms-assignments-mfe':
                return ['https://bcf476d5ee194b93a1c022fde1c2f98c@o11481.ingest.sentry.io/4505209951682560'];
            case 'ms-breadcrumb-nav-mfe':
                return ['https://18c110c414a043b29c740ca2d5c04f8f@o11481.ingest.sentry.io/4505209953255424'];
            case 'ms-digital-glossary-mfe':
                return ['https://8b3908be99e545829deb3e6d0379aee8@o11481.ingest.sentry.io/4505209954435072'];
            case 'ms-favourites-mfe':
                return ['https://ace58459010f4c1c95a66916df3aa9be@o11481.ingest.sentry.io/4505209955811328'];
            case 'ms-footer-mfe':
                return ['https://63660afa8b9743f5bf5149952cda91ed@o11481.ingest.sentry.io/4505209956990976'];
            case 'ms-lesson-explorer-mfe':
                return ['https://fb9487cd441d46e188d0c0b21dcc89c3@o11481.ingest.sentry.io/4505209958432768'];
            case 'ms-navbar-mfe':
                return ['https://b817a2324a2e45219d3fbf38948c48fd@o11481.ingest.sentry.io/4505209959546880'];
            case 'ms-standards-mfe':
                return ['https://51b36b5b66c742ec87277f04413f4226@o11481.ingest.sentry.io/4505209960923136'];
            case 'ms-student-dashboard-mfe':
                return ['https://43c582307ff549fb9b179d6a28a60b97@o11481.ingest.sentry.io/4505209962168320'];
            case 'ms-student-session-mfe':
                return ['https://58cc2524b6db4e54958bae4119d7edd5@o11481.ingest.sentry.io/4505209963282432'];
            case 'ms-teacher-dashboard-mfe':
                return ['https://e2ffb801f29545ae83136fb6de5c4a1f@o11481.ingest.sentry.io/4505209964527616'];
            case 'ms-teacher-feedback-mfe':
                return ['https://375737e03b924938a4ede9d0c7c8557e@o11481.ingest.sentry.io/4505209965903872'];
            case 'ms-teacher-knowledge-mfe':
                return ['https://0678b3c5042540f4b5e9fb3f29f30dce@o11481.ingest.sentry.io/4505209967083520'];
            case 'ms-teacher-session-mfe':
                return ['https://829b6f47f9ca4ef6831edb02339777a6@o11481.ingest.sentry.io/4505209968197632'];
            case 'ts-accounts':
                return ['https://f04813f27bbd4930bf0f7101e0467111@o11481.ingest.sentry.io/1423030'];
            case 'ts-credits-mfe':
                return ['https://586573cdf8344b2cb0c9b7e38ad65c78@o11481.ingest.sentry.io/4505209970491392'];
            case 'twig-drawing-tool':
                return ['https://ef5c7ac707d0478f9a22cdcc21e3a1bb@o11481.ingest.sentry.io/4505204279672832'];
            case 'twig-pdf':
                return ['https://83b7eeb2140b4ff4885b8d5e5db23821@o11481.ingest.sentry.io/4505204282294272'];
            case 'twig-quill-mfe':
                return ['https://0d95183b4dbe45c5aa8da9a2fdc45578@o11481.ingest.sentry.io/4505204286947328'];
            case 'twig-video-mfe':
                return ['https://271e538a72f04bc99ac5531dbb599d9e@o11481.ingest.sentry.io/4505204288192512'];
            default:
        }
        return [];
    };
    const tracePropagationTargets = ['/localhost/', '/ms.twigscience.com/', '/api.twigscience.com/', '/^//'];
    Sentry.init({
        dsn: window.config.SENTRY_PUBLIC_DSN,
        transport: makeMultiplexedTransport(Sentry.makeFetchTransport, dsnFromFeature),
        release: window.config.IMAGE_GIT_COMMIT_SHA,
        environment: window.config.SENTRY_ENVIRONMENT,
        attachStacktrace: true,
        normalizeDepth: 10,
        tracesSampleRate: 0.05,
        tracePropagationTargets,
        integrations: [
            new BrowserTracing({
                tracePropagationTargets,
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
            new SentryRRWeb({
                maskAllInputs: true,
            }),
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /analytics\.js/i,
            /scripts\.hotjar\.com/i,
            /js-agent\.newrelic\.com/i,
            /acsbapp\.com/i,
            /g1980843350\.co/i,
        ],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            "Can't find variable: ZiteReader",
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            /\?\(ga\)/i,
            /acsb/i,
            // Rapid page reflows triggers this. Doesn't affect the site.
            'ResizeObserver loop limit exceeded',
        ],
    });
}

const ContentComponent = connect(null, { setFeatureFlags })(props => (
    <>
        <CustomUnleash>
            <UnleashConsumer>
                {featureFlags => {
                    props.setFeatureFlags(featureFlags);
                    return (
                        <>
                            <Suspense fallback={<p>Loading...</p>}>
                                <ApolloProvider client={client}>
                                    <Subscriptions store={store}>
                                        <LoadManifests manifestServiceUrl={window.config.MANIFEST_SERVICE_URL}>
                                            <I18n>
                                                <DevTools />
                                                <Router history={history}>
                                                    <Routes />
                                                </Router>
                                            </I18n>
                                        </LoadManifests>
                                    </Subscriptions>
                                </ApolloProvider>
                            </Suspense>
                        </>
                    );
                }}
            </UnleashConsumer>
        </CustomUnleash>
    </>
));

const StyledPageError = styled(PageError)`
    height: 100vh;
    justify-content: center;
`;

const RootLevelError = () => (
    <StyledPageError
        title="Oops! Something went wrong!"
        body="We're sorry about that, we'll try to stop it from happening again"
        buttonConfig={{
            label: 'Go to my Dashboard',
            href: '/',
            primary: true,
        }}
    />
);

const MainComponent = ErrorBoundary(
    'Top level page error',
    [],
    RootLevelError,
)(() => (
    <ApolloProvider client={client}>
        <Provider store={store}>
            <AuthenticationProvider userManager={userManager} store={store}>
                <ContentComponent />
            </AuthenticationProvider>
        </Provider>
    </ApolloProvider>
));

const appElement = document.getElementById('app');
initGA(appElement);

ReactDOM.render(
    <ThemeProvider theme={middleSchoolTheme}>
        <GlobalStyle />
        <GlobalFonts />
        <GlobalReadSpeakerStyles />
        <MainComponent />
    </ThemeProvider>,
    appElement,
);
